import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseApp = initializeApp({
    apiKey: "AIzaSyB9JLGu8XuPmnTv8v1yukxx5Fwj6AUCSO4",
    authDomain: "kizana-fz-llc.firebaseapp.com",
    projectId: "kizana-fz-llc",
    storageBucket: "kizana-fz-llc.appspot.com",
    messagingSenderId: "656945547997",
    appId: "1:656945547997:web:01ec1f29bda3f57acf2934",
    measurementId: "G-8S0YBPP5NC",
});

export default firebaseApp;
export const auth = getAuth(firebaseApp);

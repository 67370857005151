import { createStore } from "vuex";

const storage = createStore({
    state: {
        level: JSON.parse(localStorage.getItem("level") )|| null,
        token: JSON.parse(localStorage.getItem("token") )|| null,
        tokenExpiration: JSON.parse(localStorage.getItem("tokenExpiration") )|| null,
    },
    mutations: {
        setUserData(state, { level, token, tokenExpiration }) {
            state.level = level;
            state.token = token;
            state.tokenExpiration = tokenExpiration;
            localStorage.setItem("level", JSON.stringify(level));
            localStorage.setItem("token", JSON.stringify(token));
            localStorage.setItem(
                "tokenExpiration",
                JSON.stringify(tokenExpiration)
            );
        },
        clearUserData(state) {
            state.level = null;
            state.token = null;
            state.tokenExpiration = null;
            localStorage.removeItem("level");
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
        },
    },
    actions: {
        login({ commit }, { level, token, tokenExpiration }) {
            commit("setUserData", { level, token, tokenExpiration });
        },
        logout({ commit }) {
            commit("clearUserData");
        },
    },
});
export default storage;

<!-- src/App.vue -->

<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
// import LoginPage from "@/components/LoginPage.vue";
// import DashbaordPage from "@/components/DashboardPage.vue";

export default {
    name: "App",
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px; /* Add some top margin to give space for a fixed navigation bar */
}
</style>

<template>
    <BackButton />
    <div class="title">
        <h1>Brands</h1>
    </div>
    <div class="subtitle">
        <h2>
            Brands With Logos =
            {{
                (this.brands.filter(
                    (brand) =>
                        brand.brand_logo_URL != null &&
                        brand.brand_logo_URL.trim() !== ""
                ).length /
                    this.brands.length) *
                100
            }}%
        </h2>
    </div>
    <div>
        <table class="brand-table">
            <thead>
                <tr>
                    <th>Brand Name</th>
                    <th>Brand Logo URL</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(brand, index) in brands"
                    :key="index"
                    :class="{ 'editing-row': editing === index }"
                >
                    <td>
                        <input
                            v-model="brand.brand_name"
                            :disabled="editing !== null && editing !== index"
                        />
                    </td>
                    <td>
                        <input
                            v-model="brand.brand_logo_URL"
                            :disabled="editing !== null && editing !== index"
                        />
                    </td>
                    <td>
                        <button
                            @click="editRow(index)"
                            v-if="editing !== index"
                        >
                            Edit
                        </button>
                        <button
                            @click="saveRow(index)"
                            v-if="editing === index"
                        >
                            Save
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button @click="addRow" class="add-button">Add Row</button>
    </div>
</template>

<script>
import api from "../api.js";
import BackButton from "./small-components/BackButton.vue";
export default {
    components: {
        BackButton,
    },
    data() {
        return {
            brands: [],
            editing: null,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            api.post("brand/list").then((response) => {
                if (response.status == 200) {
                    this.brands = response.data["brands"];
                }
            });
        },
        editRow(index) {
            this.editing = index;
        },
        saveRow(index) {
            try {
                if (this.brands[index].cb_id == null) {
                    api.post("brand/add", {
                        brandName: this.brands[index].brand_name,
                        brandLogoURL: this.brands[index].brand_logo_URL,
                    }).then((response) => {
                        this.brands[index].cb_id = response.data["brandId"];
                        this.editing = null;
                    });
                    return;
                } else {
                    api.post("brand/edit", {
                        brandId: this.brands[index].cb_id,
                        brandName: this.brands[index].brand_name,
                        brandLogoURL: this.brands[index].brand_logo_URL,
                    }).then((response) => {
                        console.log(response.status);
                        this.editing = null;
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        addRow() {
            this.brands.push({
                cb_id: null,
                brand_name: "",
                brand_logo_URL: "",
            });
        },
    },
};
</script>

<style scoped>
.brand-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.brand-table th,
.brand-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.editing-row {
    background-color: #f0f8ff; /* Light blue color for editing rows */
}

.add-button {
    padding: 8px;
    background-color: #4caf50; /* Green color for add button */
    color: #fff;
    border: none;
    cursor: pointer;
}

.add-button:hover {
    background-color: #45a049; /* Darker green color on hover */
}
</style>

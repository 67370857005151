import axios from "axios";
import storage from "./storage";
import router from "./routes"; // Adjust the path as needed

const api = axios.create({
    baseURL: "https://api.kizana.co/api/admin/",
    timeout: 30000,
    headers: { "Content-Type": "application/json" },
});
// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = storage.state.token;
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            storage.dispatch("logout");
            router.push("/login");
        } else if (error.response && error.response.status === 409) {
            router.push("/");
        }
        return Promise.reject(error);
    }
);

export default api;

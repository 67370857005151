<template>
    <button @click="goBack" class="back-button">Back</button>
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.back-button {
    cursor: pointer;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #0056b3;
}
</style>

<template>
    <BackButton />
    <div>
        <h2>Segmentation Analytics</h2>
    </div>
    <div class="analytics-container">
        <div class="date-picker">
            <label for="fromDate">From:</label>
            <input
                type="date"
                id="fromDate"
                class="form-control"
                v-model="fromDate"
                @change="fetchData"
            />
        </div>

        <div class="date-picker">
            <label for="toDate">To:</label>
            <input
                type="date"
                id="toDate"
                class="form-control"
                v-model="toDate"
                @change="fetchData"
            />
        </div>

        <div v-if="analyticsData" class="analytics-results">
            <h2>Analytics Data:</h2>
            <div
                v-for="(value, key) in analyticsData"
                :key="key"
                class="analytics-item"
            >
                {{ camelCaseToRegularText(key) }} : {{ value }}
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import BackButton from "./small-components/BackButton.vue";
export default {
    components: {
        BackButton,
    },
    data() {
        return {
            fromDate: getFormattedDate(
                new Date(new Date().setMonth(new Date().getMonth() - 1))
            ),
            toDate: getFormattedDate(new Date()),
            analyticsData: null,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        camelCaseToRegularText(camelCaseString) {
            // Use a regular expression to insert a space before each uppercase letter
            // and then replace the first character with its uppercase version
            return camelCaseString
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, function (str) {
                    return str.toUpperCase();
                });
        },
        fetchData() {
            const fromTimestamp = new Date(this.fromDate).getTime();
            const toTimestamp = new Date(this.toDate).getTime();

            // Make a request to your MySQL server with the date range
            api.post("/segmentation", {
                startDate: fromTimestamp,
                endDate: toTimestamp,
            })
                .then((response) => {
                    this.analyticsData = response.data;
                    console.log(response);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        },
    },
};

function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}
</script>

<style scoped>
.analytics-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.date-picker {
    margin-bottom: 15px;
}

.analytics-results {
    margin-top: 20px;
}

.analytics-item {
    margin-bottom: 10px;
}

.analytics-item strong {
    display: inline-block;
    width: 120px;
}

.analytics-item span {
    margin-left: 10px;
    font-weight: bold;
}
</style>

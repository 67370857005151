<template>
    <div>
        <BackButton />
        <h1>Categories</h1>
        <h2 style="color: red">
            WARNING: As they're complicated to remove, adding a category is
            currently irreversible, please make sure you have the correct SVG
            text for the image as otherwise it would break the app
        </h2>
        <h2>
            Clothe Category ID: 1 = Tops, 2 = Bottoms, 3 = Shoes, 4 =
            Accessories
        </h2>
        <table class="category-table">
            <thead>
                <tr>
                    <th>Clothe Category ID</th>
                    <th>Category Name</th>
                    <th>Category Description</th>
                    <th>Category Icon URL</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(category, index) in categories"
                    :key="index"
                    :class="{ 'editing-row': editing === index }"
                >
                    <td>
                        <input
                            v-model="category.clothe_category_id"
                            :disabled="editing !== null && editing !== index"
                        />
                    </td>
                    <td>
                        <input
                            v-model="category.category_name"
                            :disabled="editing !== null && editing !== index"
                        />
                    </td>
                    <td>
                        <input
                            v-model="category.category_description"
                            :disabled="editing !== null && editing !== index"
                        />
                    </td>
                    <td>
                        <input
                            v-model="category.category_icon_URL"
                            :disabled="editing !== null && editing !== index"
                        />
                    </td>
                    <td>
                        <button
                            @click="editRow(index)"
                            v-if="editing !== index"
                        >
                            Edit
                        </button>
                        <button
                            @click="saveRow(index)"
                            v-if="editing === index"
                        >
                            Save
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button @click="addRow" class="add-button">Add Row</button>
    </div>
</template>

<script>
import api from "../api.js";
import BackButton from "./small-components/BackButton.vue";
export default {
    components: {
        BackButton,
    },
    data() {
        return {
            categories: [],
            editing: null,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            api.post("subcategory/list").then((response) => {
                if (response.status == 200) {
                    this.categories = response.data["subcategories"];
                }
            });
        },
        editRow(index) {
            this.editing = index;
        },
        saveRow(index) {
            try {
                if (this.categories[index].csca_id == null) {
                    api.post("subcategory/add", {
                        clotheCateogryId:
                            this.categories[index].clothe_category_id,
                        categoryName: this.categories[index].category_name,
                        categoryDescription:
                            this.categories[index].category_description,
                        categoryIconURL:
                            this.categories[index].category_icon_URL,
                    }).then((response) => {
                        this.categories[index].cb_id =
                            response.data["categoryId"];
                        this.editing = null;
                    });
                    return;
                } else {
                    api.post("subcategory/edit", {
                        categoryId: this.categories[index].csca_id,
                        clotheCateogryId:
                            this.categories[index].clothe_category_id,
                        categoryName: this.categories[index].category_name,
                        categoryDescription:
                            this.categories[index].category_description,
                        categoryIconURL:
                            this.categories[index].category_icon_URL,
                    }).then((response) => {
                        console.log(response.status);
                        this.editing = null;
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        addRow() {
            this.categories.push({
                csca_id: null,
                clothe_category_id: "",
                category_name: "",
                category_description: "",
                category_icon_URL: "",
            });
        },
    },
};
</script>

<style scoped>
.category-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.category-table th,
.category-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.editing-row {
    background-color: #f0f8ff; /* Light blue color for editing rows */
}

.add-button {
    padding: 8px;
    background-color: #4caf50; /* Green color for add button */
    color: #fff;
    border: none;
    cursor: pointer;
}

.add-button:hover {
    background-color: #45a049; /* Darker green color on hover */
}
</style>

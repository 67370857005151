import { createRouter, createWebHistory } from "vue-router";
import DashboardPage from "@/components/DashboardPage.vue";
import LoginPage from "@/components/LoginPage.vue";
import BrandsPage from "@/components/BrandsPage.vue";
import CategoriesPage from "@/components/CategoriesPage.vue";
import SegmentationPage from "@/components/SegmentationPage.vue";
import UserAnalyticsPage from "@/components/UserAnalyticsPage.vue";
import PackagesPage from "@/components/PackagesPage.vue";
import ManageAdmins from "@/components/ManageAdmins.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "Dashboard",
            component: DashboardPage,
            meta: {
                requiresAuth: true, // Set this to true for routes that require authentication
            },
        },
        {
            path: "/login",
            name: "Login",
            component: LoginPage,
        },
        {
            path: "/brands",
            name: "Brands",
            component: BrandsPage,
            meta: {
                requiresAuth: true, // Set this to true for routes that require authentication
            },
        },
        {
            path: "/categories",
            name: "Categories",
            component: CategoriesPage,
            meta: {
                requiresAuth: true, // Set this to true for routes that require authentication
            },
        },
        {
            path: "/segmentation",
            name: "Segmentation",
            component: SegmentationPage,
            meta: {
                requiresAuth: true, // Set this to true for routes that require authentication
            },
        },
        {
            path: "/user-analytics",
            name: "UserAnalytics",
            component: UserAnalyticsPage,
            meta: {
                requiresAuth: true, // Set this to true for routes that require authentication
            },
        },
        {
            path: "/packages",
            name: "Packages",
            component: PackagesPage,
            meta: {
                requiresAuth: true, // Set this to true for routes that require authentication
            },
        },
        {
            path: "/manage-admins",
            name: "ManageAdmins",
            component: ManageAdmins,
            meta: {
                requiresAuth: true, // Set this to true for routes that require authentication
            },
        },
    ],
});

export default router;

import { createApp } from "vue";
import router from "./routes.js";
import App from "./App.vue";
import storage from "./storage.js"; // Assuming you have a Vuex store setup
import { VueFire, VueFireAuth } from 'vuefire'
import firebaseApp from './firebase'
// Add a navigation guard
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const hasValidSession = checkValidSession(storage.state.tokenExpiration);

    if (requiresAuth && !hasValidSession) {
        // Redirect to the login page if authentication is required and conditions are not met
        next({ name: "Login" });
    } else {
        // Continue to the requested route
        next();
    }
});
function checkValidSession(tokenExpiration) {
    if (!tokenExpiration) {
        // No token expiration info, consider the session as invalid
        return false;
    }

    // Convert the token expiration time to milliseconds
    const tokenExpirationMillis = tokenExpiration * 1000;
    const currentTimeMillis = Date.now();

    // Check if the token is still valid
    return tokenExpirationMillis > currentTimeMillis;
}
const app = createApp(App);
app.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
      // we will see other modules later on
      VueFireAuth(),
    ],
  })
app.use(router);
app.use(storage);

app.mount("#app");

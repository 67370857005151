<template>
    <div>
        <BackButton />
        <h2>Packages</h2>
        <table class="package-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Price (USD)</th>
                    <th>Duration</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(pkg, index) in packages"
                    :key="index"
                    :class="{ 'editing-row': editing === index }"
                >
                    <td>{{ pkg.name }}</td>
                    <td>
                        <input
                            v-model="pkg.price"
                            :disabled="editing !== null && editing !== index"
                        />
                    </td>
                    <td>{{ pkg.duration }}</td>
                    <td>{{ pkg.description }}</td>
                    <td>
                        <button
                            @click="editRow(index)"
                            v-if="editing !== index"
                        >
                            Edit
                        </button>
                        <button
                            @click="saveRow(index)"
                            v-if="editing === index"
                        >
                            Save
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import api from "@/api";
import BackButton from "./small-components/BackButton.vue";
export default {
    components: {
        BackButton,
    },
    data() {
        return {
            packages: [],
            editing: null,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            api.post("/payment/list").then((response) => {
                if(response.status == 200){
                    this.packages = response.data;
                }
            });
        },
        editRow(index) {
            this.editing = index;
        },
        saveRow(index) {
            api.post("/payment/edit", {
                packageId: this.packages[index].p_id,
                price: this.packages[index].price,
            }).then((response) => {
                if(response.status == 200){
                    this.editing = null;
                }
            });
        },
    },
};
</script>

<style scoped>
.package-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.package-table th,
.package-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.editing-row {
    background-color: #f0f8ff; /* Light blue color for editing rows */
}
</style>

<!-- src/components/Login.vue -->

<template>
    <div class="login-container">
        <h2>Login</h2>
        <form @submit.prevent="login" class="login-form">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="email" required />

            <label for="password">Password:</label>
            <input type="password" id="password" v-model="password" required />

            <button v-on:click="login">Login</button>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.js";
export default {
    data() {
        return {
            email: "",
            password: "",
        };
    },
    methods: {
        async login() {
            // Simple email validation
            if (!this.isValidEmail(this.email)) {
                alert("Please enter a valid email address.");
                return;
            }
            //PASSWORD SHOULD BE LONGER THAN 8 CHARACTERS AND AT LEAST 1 CAPITAL LETTER, 1 NUMBER, 1 SPECIAL SYMBOL, 1 LOWERCASE LETTER
            if (this.password.trim() === "") {
                alert("Please enter a password.");
                return;
            } else if (this.password.length < 8) {
                alert("Password should be longer than 8 characters.");
                return;
            } else if (!this.password.match(/[A-Z]/)) {
                alert("Password should contain at least 1 capital letter.");
                return;
            } else if (!this.password.match(/[0-9]/)) {
                alert("Password should contain at least 1 number.");
                return;
            } else if (
                !this.password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/)
            ) {
                alert("Password should contain at least 1 special symbol.");
                return;
            } else if (!this.password.match(/[a-z]/)) {
                alert("Password should contain at least 1 lowercase letter.");
                return;
            }

            try {
                //Auhtenticate with firebase then go to backend with the email and firebase uid
                signInWithEmailAndPassword(auth, this.email, this.password)
                    .then(async (userCredential) => {
                        // Signed in
                        const user = userCredential.user.uid;
                        const response = await axios.post(
                            "https://api.kizana.co/api/admin/login",
                            {
                                firebaseUID: user,
                                email: this.email,
                            }
                        );
                        if (response.status === 200) {
                            const { level, token } = response.data;
                            const tokenExpiration =
                                Math.floor(Date.now() / 1000) + 3600;
                            this.$store.dispatch("login", {
                                level,
                                token,
                                tokenExpiration,
                            });
                            this.$router.push("/");
                        } else {
                            alert("Login Failed");
                        }
                    })
                    .catch(() => {
                        alert("Login Failed");
                    });
            } catch (error) {
                console.log(error);
                alert("Login Failed");
            }
        },
        isValidEmail(email) {
            // Basic email validation regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
    },
};
</script>

<style scoped>
/* Add your styles here */

.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #333;
}

.login-form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
}

input {
    padding: 8px;
    margin-bottom: 10px;
}

button {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}
</style>

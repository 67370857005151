<!-- CountdownTimer.vue -->
<template>
    <div class="countdown-timer" :class="{ 'red-text': remainingTime <= 600 }">
        Session expires in: {{ formattedTime }}
    </div>
</template>

<script>
export default {
    data() {
        return {
            remainingTime: this.calculateRemainingTime(),
        };
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.remainingTime / 60);
            const seconds = this.remainingTime % 60;
            return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
        },
    },
    methods: {
        calculateRemainingTime() {
            return Math.max(
                0,
                Math.floor(
                    this.$store.state.tokenExpiration - Date.now() / 1000
                )
            );
        },
        updateRemainingTime() {
            this.remainingTime = this.calculateRemainingTime();
            if (this.remainingTime === 0) {
                this.$store.dispatch("logout");
            }
        },
    },
    mounted() {
        // Update the timer every second
        this.timerInterval = setInterval(this.updateRemainingTime, 1000);
    },
    beforeUnmount() {
        // Clear the interval to prevent memory leaks
        clearInterval(this.timerInterval);
    },
};
</script>

<style scoped>
.countdown-timer {
    font-size: 18px;
}

.red-text {
    color: red;
}
</style>

<template>
    <div>
        <BackButton />
        <div>
            <h2>User Analytics</h2>
        </div>
        <div v-if="analyticsData" class="analytics-container">
            <h2>Active Users:</h2>
            <div
                v-for="(value, key) in analyticsData"
                :key="key"
                class="analytics-item"
            >
                {{ camelCaseToRegularText(key) }} : {{ value }}
            </div>
        </div>

        <div v-if="isSuperAdmin" class="analytics-container">
            <h2>Active Users Definition:</h2>
            <table class="table">
                <thead>
                    <tr>
                        <th>Time (Days)</th>
                        <th>Active</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="definition in activeUserDefinitions"
                        :key="definition.id"
                    >
                        <td>{{ definition.time }}</td>
                        <td>{{ definition.active ? "Active" : "Inactive" }}</td>
                        <td>
                            <button
                                v-if="!definition.active"
                                @click="editDefinition(definition)"
                            >
                                Set As Active
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import BackButton from "./small-components/BackButton.vue";
export default {
    components: {
        BackButton,
    },
    data() {
        return {
            analyticsData: null,
            isSuperAdmin:
                JSON.parse(localStorage.getItem("level")) === "sadmin",
            activeUserDefinitions: null,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        camelCaseToRegularText(camelCaseString) {
            return camelCaseString
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, function (str) {
                    return str.toUpperCase();
                });
        },
        fetchData() {
            api.post("/active-users/count")
                .then((response) => {
                    this.analyticsData = response.data;
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            if (this.isSuperAdmin) {
                api.post("/active-users/list")
                    .then((response) => {
                        this.activeUserDefinitions = response.data;
                    })
                    .catch((error) => {
                        console.error("Error fetching definitions:", error);
                    });
            }
        },
        editDefinition(definition) {
            if (this.isSuperAdmin) {
                api.post("/active-users/edit", {
                    activeUserId: definition.id,
                })
                    .then((response) => {
                        if (response.status === 200) {
                            //TO FIX: SET OLD TRUE TO FALSE AND NEW DEFINITION TO TRUE
                            this.activeUserDefinitions.forEach(
                                (oldDefinition) => {
                                    if (oldDefinition.id === definition.id) {
                                        oldDefinition.active = true;
                                    } else if (oldDefinition.active) {
                                        oldDefinition.active = false;
                                    }
                                }
                            );
                            this.fetchData();
                        }
                    })
                    .catch((error) => {
                        console.error("Error editing definition:", error);
                    });
            }
        },
    },
};
</script>

<style scoped>
/* Your existing styles go here */

.table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}
.analytics-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.analytics-item {
    margin-bottom: 10px;
}

.analytics-item strong {
    display: inline-block;
    width: 120px;
}

.analytics-item span {
    margin-left: 10px;
    font-weight: bold;
}
</style>

<!-- Dashboard.vue -->
<template>
    <CountdownTimer></CountdownTimer>
    <div class="dashboard">
        <div
            v-for="(item, index) in filteredGridItems"
            :key="index"
            class="grid-item"
            :style="{ backgroundColor: item.color }"
        >
            <router-link v-if="!item.externalLink" :to="item.route">
                <h2>{{ item.title }}</h2>
            </router-link>
            <a v-else :href="item.route" class="external-link" target="_blank">
                <h2>{{ item.title }}</h2>
            </a>
        </div>
    </div>
</template>

<script>
import CountdownTimer from "./small-components/CountdownTimer.vue";
export default {
    components: {
        CountdownTimer,
    },
    computed: {
        filteredGridItems() {
            // Filter grid items based on the user's level
            return this.gridItems.filter((item) => {
                // If user is an admin, exclude super admin specific routes
                if (
                    this.$store.state.level === "admin" &&
                    item.superAdminOnly
                ) {
                    return false;
                }
                return true;
            });
        },
        gridItems() {
            return [
                { title: "Brands", color: "#3498db", route: "/brands" },
                {
                    title: "Website Analytics",
                    color: "#2ecc71",
                    route: "https://marketingplatform.google.com/about/analytics/",
                    externalLink: true,
                },
                {
                    title: "Packages",
                    color: "#e74c3c",
                    route: "/packages",
                    superAdminOnly: true,
                },
                { title: "Categories", color: "#f39c12", route: "/categories" },
                {
                    title: "Segmentation Analytics",
                    color: "#0bf8d8",
                    route: "/segmentation",
                },
                {
                    title: "User Analytics",
                    color: "#e5b18b",
                    route: "/user-analytics",
                },
                {
                    title: "Manage Admins",
                    color: "#9b59b6",
                    route: "/manage-admins",
                    superAdminOnly: true,
                }
                // Add more items as needed
            ];
        },
    },
};
</script>

<style scoped>
.dashboard {
    display: flex;
    flex-wrap: wrap;
}
a {
    text-decoration: none;
    color: inherit;
}

.grid-item {
    box-sizing: border-box;
    width: calc(
        33.33% - 20px
    ); /* 20px is the total margin between items (adjust as needed) */
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    transition: background-color 0.3s ease;
    text-decoration: none; /* Remove default link styling */
    color: #333; /* Set link text color */
}

.grid-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 10px;
    color: inherit; /* Inherit link text color */
}
</style>

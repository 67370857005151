<template>
    <div>
        <BackButton />
        <h2>Manage Admins</h2>
        <div class="table-container">
            <h2>Users</h2>
            <table class="user-table">
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Username</th>
                        <th>Level</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user, index) in users" :key="index">
                        <td>{{ user.user_id }}</td>
                        <td>{{ user.username }}</td>
                        <td>{{ user.level }}</td>
                        <td>
                            <button @click="transferToAdmin(index)">
                                Make Admin
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-container">
            <h2>Admins</h2>
            <table class="admin-table">
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Username</th>
                        <th>Level</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(admin, index) in admins" :key="index">
                        <td>{{ admin.user_id }}</td>
                        <td>{{ admin.username }}</td>
                        <td>{{ admin.level }}</td>
                        <td>
                            <button @click="transferToUser(index)">
                                Make User
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import BackButton from "./small-components/BackButton.vue";
export default {
    components: {
        BackButton,
    },
    data() {
        return {
            users: [],
            admins: [],
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            api.post("/admin/list").then((response) => {
                if (response.status == 200) {
                    this.users = response.data["users"];
                    this.admins = response.data["admins"];
                }
            });
        },
        transferToAdmin(index) {
            const userToTransfer = this.users[index];
            api.post("/admin/add", {
                userId: userToTransfer.user_id,
                username: userToTransfer.username,
            }).then((response) => {
                if (response.status == 200) {
                    this.users.splice(index, 1);
                    this.admins.push(userToTransfer);
                }
            });
        },
        transferToUser(index) {
            const adminToTransfer = this.admins[index];
            api.post("/admin/remove", {
                userId: adminToTransfer.user_id,
                username: adminToTransfer.username,
            }).then((response) => {
                if (response.status == 200) {
                    this.admins.splice(index, 1);
                    this.users.push(adminToTransfer);
                }
            });
        },
    },
};
</script>

<style>
.table-container {
    margin-bottom: 20px;
}

.user-table,
.admin-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.user-table th,
.user-table td,
.admin-table th,
.admin-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.user-table button,
.admin-table button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
}

.user-table button:hover,
.admin-table button:hover {
    background-color: #45a049;
}
</style>
